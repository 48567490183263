<template>
  <v-navigation-drawer
    :value="isModelCreateActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 900 : '100%'"
    app
    @input="(val) => $emit('update:is-model-create-active', val)"
    :stateless="loading"
  >
    <v-stepper
      v-model="step"
      vertical
      style="height: 100%; overflow: auto"
      class="pb-0"
    >
      <v-container style="height: 100%">
        <v-row>
          <!-- stepper -->
          <v-col
            cols="12"
            md="4"
            style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
          >
            <v-row>
              <!-- title -->
              <v-col cols="12" class="d-flex pb-0">
                <div class="text-h6">Train new model</div>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  @click="$emit('update:is-model-create-active', false)"
                >
                  <v-icon size="22" class="d-flex d-md-none">
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>

              <!-- stepper content -->
              <v-col cols="12" class="pa-0">
                <v-list class="pt-0">
                  <v-list-item :class="{ 'step-list': step === '1' }">
                    <v-stepper-step
                      :complete="step > '1'"
                      :editable="maxStep > '1'"
                      step="1"
                    >
                      Training method
                    </v-stepper-step>
                  </v-list-item>
                  <v-list-item :class="{ 'step-list': step === '2' }">
                    <v-stepper-step
                      :complete="step > '2'"
                      :editable="maxStep > '2'"
                      step="2"
                    >
                      Model details
                    </v-stepper-step>
                  </v-list-item>
                  <v-list-item :class="{ 'step-list': step === '3' }">
                    <v-stepper-step
                      :complete="step > '3'"
                      :editable="maxStep > '3'"
                      step="3"
                    >
                      Training options
                    </v-stepper-step>
                  </v-list-item>
                  <!-- <v-list-item :class="{ 'step-list': step === '4' }">
                    <v-stepper-step
                      :complete="step > '4'"
                      :editable="maxStep > '4'"
                      step="4"
                    >
                      Tactic group
                    </v-stepper-step>
                  </v-list-item> -->
                  <v-list-item :class="{ 'step-list': step === '4' }">
                    <v-stepper-step
                      :complete="step > '4'"
                      :editable="maxStep > '4'"
                      step="4"
                    >
                      Training Confounder
                    </v-stepper-step>
                  </v-list-item>
                </v-list>
              </v-col>

              <!-- button -->
              <v-col cols="12" class="d-flex pt-0">
                <v-btn
                  color="primary"
                  class="me-3"
                  @click.prevent="onTrain"
                  :disabled="onTrainBtnLocked"
                >
                  START TRAINING
                </v-btn>
                <v-btn
                  text
                  @click="$emit('update:is-model-create-active', false)"
                >
                  CANCEL
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <!-- content -->
          <v-col cols="12" md="8">
            <v-stepper-items>
              <!-- Training method -->
              <v-stepper-content step="1">
                <template>
                  <form class="mt-5">
                    <v-select
                      v-model="dataset"
                      :items="datasetItems"
                      item-text="name"
                      item-value="id"
                      label="Dataset"
                      required
                      @change="onChangeDatasetGroup"
                      @blur="$v.dataset.$touch()"
                      dense
                    ></v-select>
                    <v-select
                      v-model="trainParam.model_type"
                      :items="objectiveItems"
                      label="Objective"
                      required
                      @change="$v.trainParam.model_type.$touch()"
                      @blur="$v.trainParam.model_type.$touch()"
                      dense
                    ></v-select>

                    <v-btn
                      color="primary"
                      @click="
                        () => {
                          step = '2';
                          maxStep = '2';
                        }
                      "
                    >
                      Continue
                    </v-btn>
                  </form>
                </template>
              </v-stepper-content>

              <!-- Model details -->
              <v-stepper-content step="2">
                <form class="mt-5">
                  <v-text-field
                    v-model="trainParam.model_name"
                    label="Model name"
                    :error-messages="nameErrors"
                    required
                    @change="$v.trainParam.model_name.$touch()"
                    @blur="$v.trainParam.model_name.$touch()"
                    dense
                  ></v-text-field>
                  <v-select
                    v-model="trainParam.y_var"
                    :items="targetColumnItems"
                    label="Target column"
                    :error-messages="targetTraitErrors"
                    required
                    @change="$v.trainParam.y_var.$touch()"
                    @blur="$v.trainParam.y_var.$touch()"
                    dense
                  ></v-select>
                  <v-select
                    v-model="trainParam.time_stamp"
                    :items="colnameForTimeStamp"
                    label="Time stamp"
                    :error-messages="timeStampErrors"
                    @input="$v.trainParam.time_stamp.$touch()"
                    @blur="$v.trainParam.time_stamp.$touch()"
                    dense
                  ></v-select>
                  <v-select
                    v-model="trainParam.segment_value"
                    :items="colnameForSegmentValue"
                    label="Segment value"
                    :error-messages="segmentValueErrors"
                    @input="$v.trainParam.segment_value.$touch()"
                    @blur="$v.trainParam.segment_value.$touch()"
                    dense
                  ></v-select>

                  <v-btn color="primary" @click="onClickModelDetailContinue">
                    Continue
                  </v-btn>
                </form>
              </v-stepper-content>

              <!-- Training options -->
              <v-stepper-content step="3">
                <div>
                  <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="colnameForTrainingTraits"
                    item-key="name"
                    show-select
                    class="elevation-1 mb-2"
                    mobile-breakpoint="0"
                    dense
                  >
                    <template v-slot:top>
                      <v-text-field
                        class="pl-4"
                        :value="`${$t('selectedTraits')}: ${selected.length}`"
                        readonly
                        :error-messages="trainingTraitsErrors"
                      ></v-text-field>
                    </template>
                  </v-data-table>
                  <v-btn color="primary" @click="onClickTrainingOptionContinue">
                    Continue
                  </v-btn>
                </div>
              </v-stepper-content>

              <!-- Tactic group -->
              <!-- <v-stepper-content step="4">
                <v-data-table
                  v-model="selectedTacticGroups"
                  :headers="groupHeaders"
                  :items="groupItems"
                  item-key="name"
                  show-select
                  class="elevation-1 mb-2"
                  mobile-breakpoint="0"
                  dense
                >
                  <template v-slot:top>
                    <v-text-field
                      class="pl-4"
                      :value="`${$t('selectedTacticGroups')}: ${
                        selectedTacticGroups.length
                      }`"
                      readonly
                      :error-messages="selectedTacticGroupsErrors"
                    ></v-text-field>
                  </template>
                </v-data-table>
                <v-btn color="primary" @click="onClickTacticGroupContinue">
                  Continue
                </v-btn>
              </v-stepper-content> -->

              <!-- Training confounder -->
              <v-stepper-content step="4">
                <v-data-table
                  v-model="selectedConfounders"
                  :headers="headers"
                  :items="confounderItems"
                  item-key="name"
                  show-select
                  class="elevation-1 mb-2"
                  mobile-breakpoint="0"
                  dense
                >
                  <template v-slot:top>
                    <v-text-field
                      class="pl-4"
                      :value="
                        `${$t('selectedConfounders')}: ${
                          selectedConfounders.length
                        }`
                      "
                      readonly
                      :error-messages="selectedConfoundersErrors"
                    ></v-text-field>
                  </template>
                </v-data-table>
              </v-stepper-content>
            </v-stepper-items>
          </v-col>
        </v-row>
      </v-container>
    </v-stepper>
  </v-navigation-drawer>
</template>

<script>
import data from "@/models/data";
import { required } from "vuelidate/lib/validators";
import _ from "lodash";

export default {
  model: {
    prop: "isModelCreateActive",
    event: "update:is-model-create-active",
  },
  props: {
    isModelCreateActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      onTrainBtnLocked: true,
      step: "1",
      maxStep: "1",
      datasetGroups: null,
      dataset: null,
      datasetItems: [],
      objectiveItems: [],
      targetColumnItems: [],
      trainParam: {
        id: null,
        time_stamp: null,
        segment_value: null,
        y_var: null,
        x_var: null,
        model_name: null,
        model_type: null,
      },
      selected: [],
      selectedConfounders: [],
    };
  },
  validations: {
    dataset: {
      required,
    },
    trainParam: {
      time_stamp: {
        required,
      },
      segment_value: {
        required,
      },
      y_var: {
        required,
      },
      model_name: {
        required,
      },
      model_type: {
        required,
      },
    },
    selected: {
      required,
    },
    // selectedTacticGroups: {
    //   required,
    // },
    selectedConfounders: {
      required,
    },
  },
  methods: {
    async getDatasetGroups() {
      let res = await data.getDatasetGroups();
      this.datasetGroups = res.data.DatasetGroup;
      const id = this.$route.params.id;
      if (id) {
        // model create from the datasetGroups page
        // get specific datasetGroup by id
        const datasetGroup = this.datasetGroups.find((datasetgroup) => {
          return datasetgroup.id === parseInt(id);
        });
        // set dataset options
        this.datasetItems = [
          {
            name: datasetGroup.name,
            id: datasetGroup.id,
          },
        ];

        // set default option
        this.dataset = this.datasetItems[0];

        // click default option
        this.onChangeDatasetGroup(id);
      } else {
        // model create from other pages
        // set dataset options
        this.datasetItems = this.datasetGroups.map((item) => {
          return {
            name: item.name,
            id: item.id,
          };
        });
      }
    },
    async getColnameCSV(id) {
      try {
        const res = await data.getColnameCSV(id);
        this.targetColumnItems = res.data.columns;
        this.trainParam.id = id;
      } catch (error) {
        console.log("error");
      }
    },
    async onChangeDatasetGroup(id) {
      // get objective options
      const datasetGroup = this.datasetGroups.find((datasetGroup) => {
        return datasetGroup.id === parseInt(id);
      });
      const datasetGroupDomains = await data.getDatasetGroupDomains();

      const datasetGroupDomain = datasetGroupDomains.find(
        (datasetGroupDomain) => {
          return datasetGroupDomain.id === datasetGroup.domain;
        }
      );

      this.objectiveItems = [datasetGroupDomain.display_name];

      // set default option
      this.trainParam.model_type = this.objectiveItems[0];

      // get data dataset
      const datasets = await data.getDatasets(datasetGroup.id);
      const dataDataset = datasets.find((dataset) => {
        return dataset.name === "data";
      });

      // get column name of selected dataset
      const response = await data.getDatasetHeaders({
        datasetGroupId: datasetGroup.id,
        datasetId: dataDataset.id,
      });

      this.targetColumnItems = response;
    },
    async onTrain() {
      this.onTrainBtnLocked = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("error");
      } else {
        const x_var = _.map(this.selected, "name");
        const x_confounder = _.map(this.selectedConfounders, "name");
        this.trainParam.x_var = x_var;
        const payload = {
          arguments: {
            segment_value: this.trainParam.segment_value, // "state"
            time_stamp: this.trainParam.time_stamp, // "time_stamp"
            x_confounder: x_confounder, // ["trend"]
            x_tactic: this.trainParam.x_var, // ["consumer_crm", "consumer_website"]
            y_var: this.trainParam.y_var, // "total_rx"
          },
          dataset_group: this.$route.params.id, //  1
          name: this.trainParam.model_name,
        };

        try {
          const response = await data.createModel(payload);
          if (response.success === true) {
            // this.$store.dispatch("fetchModel");
            this.$dialog.message.success(this.$t("modelCreated"), {
              position: "top",
            });
            this.$router.push("/models");
          }
        } catch (error) {
          console.error(error);
        }
      }
      this.onTrainBtnLocked = false;
    },
    onClickModelDetailContinue() {
      this.$v.trainParam.model_name.$touch();
      this.$v.trainParam.y_var.$touch();
      this.$v.trainParam.time_stamp.$touch();
      this.$v.trainParam.segment_value.$touch();
      const isModelNameInvalid = this.$v.trainParam.model_name.$invalid;
      const isYVarInvalid = this.$v.trainParam.y_var.$invalid;
      const isTimeStampInvalid = this.$v.trainParam.time_stamp.$invalid;
      const isSegmentValueInvalid = this.$v.trainParam.segment_value.$invalid;
      if (
        isModelNameInvalid ||
        isYVarInvalid ||
        isTimeStampInvalid ||
        isSegmentValueInvalid
      ) {
        return;
      } else {
        this.step = "3";
        this.maxStep = "3";
      }
    },
    onClickTrainingOptionContinue() {
      this.$v.selected.$touch();
      const isSelectedInvalid = this.$v.selected.$invalid;
      if (isSelectedInvalid) {
        return;
      } else {
        this.step = "4";
        this.maxStep = "4";
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("traitName"),
          value: "name",
        },
      ];
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.trainParam.model_name.$dirty) return errors;
      !this.$v.trainParam.model_name.required &&
        errors.push(this.$t("required"));
      return errors;
    },
    targetTraitErrors() {
      const errors = [];
      if (!this.$v.trainParam.y_var.$dirty) return errors;
      !this.$v.trainParam.y_var.required && errors.push(this.$t("required"));
      return errors;
    },
    timeStampErrors() {
      const errors = [];
      if (!this.$v.trainParam.time_stamp.$dirty) return errors;
      !this.$v.trainParam.time_stamp.required &&
        errors.push(this.$t("required"));
      return errors;
    },
    segmentValueErrors() {
      const errors = [];
      if (!this.$v.trainParam.segment_value.$dirty) return errors;
      !this.$v.trainParam.segment_value.required &&
        errors.push(this.$t("required"));
      return errors;
    },
    trainingTraitsErrors() {
      const errors = [];
      if (!this.$v.selected.$dirty) return errors;
      !this.$v.selected.required && errors.push(this.$t("required"));
      return errors;
    },
    selectedConfoundersErrors() {
      const errors = [];
      if (!this.$v.selectedConfounders.$dirty) return errors;
      !this.$v.selectedConfounders.required && errors.push(this.$t("required"));
      return errors;
    },
    colnameForTimeStamp() {
      return _.filter(this.targetColumnItems, (value) => {
        return value !== this.trainParam.y_var;
      });
    },
    colnameForSegmentValue() {
      return _.filter(this.colnameForTimeStamp, (value) => {
        return value !== this.trainParam.time_stamp;
      });
    },
    colnameForTrainingTraits() {
      const xTactics = _.filter(this.colnameForSegmentValue, (value) => {
        return value !== this.trainParam.segment_value;
      });
      return xTactics.map((xTactic) => {
        return { name: xTactic };
      });
    },
    confounderItems() {
      const toRemove = this.selected.map((x) => x.name);
      const xTactics = _.filter(this.colnameForSegmentValue, (value) => {
        return value !== this.trainParam.segment_value;
      });
      const items = _.filter(xTactics, (value) => {
        return !toRemove.includes(value);
      });
      return items.map((item) => {
        return { name: item };
      });
    },
    loading() {
      return this.$store.state.loader.loading;
    },
  },
  watch: {
    step(value) {
      if (value === "4") {
        this.onTrainBtnLocked = false;
      } else {
        this.onTrainBtnLocked = true;
      }
    },
  },
  created() {
    this.getDatasetGroups();
  },
};
</script>

<style scoped>
.v-stepper--vertical .v-stepper__step {
  padding: 0;
}
.v-stepper__content {
  margin: 0 !important;
}
.v-stepper--vertical .v-stepper__content {
  padding: 0 !important;
  width: auto;
}
.v-stepper--is-booted .v-stepper__content,
.v-stepper--is-booted .v-stepper__wrapper {
  transition: none !important;
}
.v-application--is-ltr
  .theme--dark.v-stepper--vertical
  .v-stepper__content:not(:last-child) {
  border-left: none;
}
.v-application--is-ltr
  .theme--light.v-stepper--vertical
  .v-stepper__content:not(:last-child) {
  border-left: none;
}
.step-list {
  border-right: 2px #7868e6 solid;
  background: rgba(0, 0, 0, 0.05);
}
</style>
